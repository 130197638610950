<template>
  <v-autocomplete
    dense
    chips
    outlined
    :hint="hint"
    :value="value"
    :label="label"
    :items="items"
    :rules="rules"
    :error="error"
    :loading="loading"
    :multiple="multiple"
    :placeholder="label"
    :disabled="disabled"
    :item-text="itemText"
    :clearable="clearable"
    :item-value="itemValue"
    :hide-details="hideDetails"
    :prepend-icon="prependIcon"
    :return-object="returnObject"
    :deletable-chips="deletableChips"
    :persistent-hint="persistentHint"
    @change="$emit('change', $event)"
  >
    <template v-slot:[`selection`]="{ item }">
      <v-chip dark small :color="item.disabled ? 'red lighten-1' : 'msaBlue'">
        <span class="overflow-x-hidden text-truncate">
          <!-- (Archived) added to item.name on backend -->
          {{ item.name }}
        </span>
      </v-chip>
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
        :style="{ display: item.disabled ? 'none' : '' }"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ item.name }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ProjectsAutoComplete',
  props: {
    value: {
      required: true,
    },
    hint: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
