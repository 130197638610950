<template>
  <v-container
    fluid
    :class="{ 'pb-12': canSignOff || item.projectId != edited.projectId }"
  >
    <v-row>
      <v-col
        cols="12"
        align="right"
        v-if="
          edited.documentSignOffStatusId !=
          $constants.ASSIGNED_DOCUMENT_STATUS.ASSIGNED
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="downloadSignOff()"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Download Sign Off</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="lightGrey">
            <v-row no-gutters align="center">
              <v-col>
                {{ title }}
              </v-col>
              <v-col class="signed-document-status shrink">
                <div
                  class="white--text text-center text-body-1"
                  :class="statusStyleClass"
                  :style="{ width: '100px' }"
                >
                  {{ edited.statusText }}
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="mt-2">
            <v-container>
              <v-row align="center">
                <v-col cols="4" align="right" class="font-weight-medium">
                  User
                </v-col>
                <v-col cols="8">
                  {{ edited.assignedToName }}
                </v-col>
                <v-col cols="4" align="right" class="font-weight-medium">
                  Project
                </v-col>
                <v-col cols="5">
                  <ProjectsAutoComplete
                    :value="edited.projectId"
                    :items="projects"
                    :hide-details="true"
                    :disabled="!canSignOff && !isEditable"
                    @change="edited.projectId = $event"
                  />
                </v-col>
                <v-col cols="4" align="right" class="font-weight-medium">
                  Assigned date
                </v-col>
                <v-col cols="8">
                  {{ edited.createdOnMobileAt | filterAsLocalDate }}
                </v-col>
                <v-col cols="4" align="right" class="font-weight-medium">
                  Completed date
                </v-col>
                <v-col cols="8">
                  {{ edited.completedAt | filterAsLocalDate }}
                </v-col>
                <v-col cols="4" align="right" class="font-weight-medium">
                  Revision
                </v-col>
                <v-col cols="8">
                  {{ edited.revision }}
                </v-col>
                <v-col cols="4" align="right" class="font-weight-medium mb-8">
                  Comments
                </v-col>
                <v-col cols="5">
                  <v-textarea
                    v-model="edited.userEnteredDescription"
                    counter
                    maxlength="2000"
                    outlined
                    :readonly="!canSignOff"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn
                    v-if="documentExists && itemLoaded"
                    class="msaBlue white--text mr-2 mb-2"
                    @click="downloadDocument"
                  >
                    download document
                  </v-btn>
                  <v-btn
                    v-if="canSignOff"
                    @click="$vuetify.goTo($refs.signatures)"
                    class="lightBg msaBlue--text font-weight-bold mr-2 mb-2"
                  >
                    click to sign
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="documentExists && itemLoaded">
        <iframe
          :src="previewUrl"
          width="100%"
          height="1000px"
          @error="test1()"
        ></iframe>
      </v-col>

      <v-col
        cols="12"
        v-else-if="!documentExists && itemLoaded"
        class="red--text text-h6"
        align="center"
      >
        Document is missing.
      </v-col>

      <v-col cols="12">
        <v-card ref="signatures">
          <v-card-title class="lightGrey"> Signatures </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td
                    class="py-2"
                    :style="
                      signatureCellStyle($constants.SIGNATURE_TYPES.CREATED_BY)
                    "
                  >
                    <FormSignature
                      :signature="edited.signatures.createdBy"
                      :typeId="$constants.SIGNATURE_TYPES.CREATED_BY"
                      :binaryType="$constants.BINARY_TYPES.DOCUMENT_SIGNATURE"
                      :isEditable="canSignOff"
                      :areAllRequiredFieldsFilled="!!edited.projectId"
                      :isPreview="false"
                      :isAssignable="false"
                      @addSignature="
                        addSignature($constants.SIGNATURE_TYPES.CREATED_BY)
                      "
                      @viewOnMap="viewOnMap(edited.signatures.createdBy)"
                    />
                  </td>
                </tr>
                <tr v-if="edited.signatures.createdBy != undefined">
                  <td
                    class="py-2"
                    :style="
                      signatureCellStyle($constants.SIGNATURE_TYPES.SUPERVISOR)
                    "
                  >
                    <FormSignature
                      :signature="edited.signatures.supervisor"
                      :typeId="$constants.SIGNATURE_TYPES.SUPERVISOR"
                      :binaryType="$constants.BINARY_TYPES.DOCUMENT_SIGNATURE"
                      :isEditable="canSignOff"
                      :areAllRequiredFieldsFilled="!!edited.projectId"
                      :isPreview="false"
                      :isAssignable="false"
                      @addSignature="
                        addSignature($constants.SIGNATURE_TYPES.SUPERVISOR)
                      "
                      @viewOnMap="viewOnMap(edited.signatures.supervisor)"
                    />
                  </td>
                </tr>
                <tr v-if="edited.signatures.createdBy != undefined">
                  <td
                    class="py-2"
                    :style="
                      signatureCellStyle($constants.SIGNATURE_TYPES.MANAGER)
                    "
                  >
                    <FormSignature
                      :signature="edited.signatures.manager"
                      :typeId="$constants.SIGNATURE_TYPES.MANAGER"
                      :binaryType="$constants.BINARY_TYPES.DOCUMENT_SIGNATURE"
                      :isEditable="canSignOff"
                      :areAllRequiredFieldsFilled="!!edited.projectId"
                      :isPreview="false"
                      :isAssignable="false"
                      @addSignature="
                        addSignature($constants.SIGNATURE_TYPES.MANAGER)
                      "
                      @viewOnMap="viewOnMap(edited.signatures.manager)"
                    />
                  </td>
                </tr>
                <tr v-if="edited.signatures.createdBy != undefined">
                  <td
                    class="py-2"
                    :style="
                      signatureCellStyle($constants.SIGNATURE_TYPES.SAFETY_REP)
                    "
                  >
                    <FormSignature
                      :signature="edited.signatures.safetyRep"
                      :typeId="$constants.SIGNATURE_TYPES.SAFETY_REP"
                      :binaryType="$constants.BINARY_TYPES.DOCUMENT_SIGNATURE"
                      :isEditable="canSignOff"
                      :areAllRequiredFieldsFilled="!!edited.projectId"
                      :isPreview="false"
                      :isAssignable="false"
                      @addSignature="
                        addSignature($constants.SIGNATURE_TYPES.SAFETY_REP)
                      "
                      @viewOnMap="viewOnMap(edited.signatures.safetyRep)"
                    />
                  </td>
                </tr>
                <tr
                  v-for="(signature, index) in additionalSignatures"
                  :key="index"
                >
                  <td
                    class="py-2"
                    :style="
                      signatureCellStyle($constants.SIGNATURE_TYPES.ADDITIONAL)
                    "
                  >
                    <FormSignature
                      :areAllRequiredFieldsFilled="!!edited.projectId"
                      :signature="signature"
                      :typeId="$constants.SIGNATURE_TYPES.ADDITIONAL"
                      :binaryType="$constants.BINARY_TYPES.DOCUMENT_SIGNATURE"
                      @viewOnMap="viewOnMap(edited.signatures.additional)"
                    />
                  </td>
                </tr>

                <tr v-if="edited.signatures.createdBy != undefined">
                  <td class="py-2">
                    <FormSignature
                      :areAllRequiredFieldsFilled="!!edited.projectId"
                      :typeId="$constants.SIGNATURE_TYPES.ADDITIONAL"
                      :binaryType="$constants.BINARY_TYPES.DOCUMENT_SIGNATURE"
                      :isEditable="canSignOff"
                      @addSignature="
                        addSignature($constants.SIGNATURE_TYPES.ADDITIONAL)
                      "
                      :isPreview="false"
                      :isAssignable="false"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <AddSignature
            ref="addSignature"
            :key="reloadAddSignature"
            :showContacts="
              selectedSignatureTypeId == $constants.SIGNATURE_TYPES.ADDITIONAL
            "
            :contacts="contacts"
            :users="users"
            :signatureTypeId="selectedSignatureTypeId"
            @signatureCreated="
              onSignatureCreated($event, selectedSignatureTypeId)
            "
            @reload="reloadAddSignature -= 1"
            @getContacts="getNecessaryData"
          />
        </v-card>
      </v-col>
    </v-row>

    <SignatureMap
      ref="signatureMap"
      :latitude="latitude"
      :longitude="longitude"
    />

    <v-bottom-navigation
      fixed
      background-color="white"
      v-if="
        canSignOff || (item.projectId != edited.projectId && !!edited.projectId)
      "
    >
      <template v-slot:default>
        <v-container fluid :class="isMobile ? '' : 'px-12'">
          <v-row>
            <v-col align="right">
              <v-btn
                v-if="canSignOff"
                class="msaBlue ml-4"
                height="36"
                :style="{
                  color:
                    edited.signatures.createdBy == undefined
                      ? 'grey !important'
                      : 'white !important',
                  'border-radius': '4px',
                }"
                :disabled="
                  edited.signatures.createdBy == undefined || !edited.projectId
                "
                @click="signOff()"
              >
                <div>
                  <v-icon> mdi-file-document-check-outline </v-icon>
                  SUBMIT
                </div>
              </v-btn>
              <v-btn
                v-if="!canSignOff && item.projectId != edited.projectId"
                class="amber lighten-1 msaBlue--text"
                height="36"
                :style="{ color: '#3564ac !important', 'border-radius': '4px' }"
                @click="updateProject()"
              >
                <div>
                  <v-icon> mdi-content-save-outline </v-icon>
                  SAVE
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-bottom-navigation>

    <v-overlay
      :value="showLoader"
      z-index="99999"
      class="text-h6"
      opacity="0.8"
    >
      <v-row align="center">
        <v-col cols="12" align="center">
          <v-progress-circular
            indeterminate
            color="#3564ac"
          ></v-progress-circular>
        </v-col>
        <v-col cols="12">
          {{ loaderText }}
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import SignatureMap from '@/components/SignatureMap.vue';
import AddSignature from '@/components/AddSignature.vue';
import FormSignature from '@/components/FormInstances/FormSignature.vue';
import ProjectsAutoComplete from '@/components/ProjectsAutoComplete.vue';
import {
  phantomAccessCheck,
  urlOrganizationCheck,
} from '../global/functions.js';

export default {
  name: 'AssignedDocumentDetailsPage',
  components: {
    SignatureMap,
    AddSignature,
    FormSignature,
    ProjectsAutoComplete,
  },
  computed: {
    statusStyleClass() {
      let classes = '';

      if (this.edited.documentSignOffStatusId) {
        switch (this.edited.documentSignOffStatusId) {
          case this.$constants.ASSIGNED_DOCUMENT_STATUS.ASSIGNED:
            classes = 'red';
            break;
          case this.$constants.ASSIGNED_DOCUMENT_STATUS.SUBMITTED:
            classes = 'green';
            break;
          default:
            break;
        }
      }

      return classes;
    },
    isEditable() {
      if (this.$store.getters.isWebAdmin) {
        return true;
      }

      return (
        this.item.documentSignOffStatusId ==
          this.$constants.ASSIGNED_DOCUMENT_STATUS.ASSIGNED &&
        this.item.createdBy == this.$store.getters.user.id
      );
    },
    canSignOff() {
      return (
        this.$route.name == 'AssignedDocumentDetails' &&
        this.edited.createdOnMobileBy == this.$store.getters.user.id &&
        this.edited.documentSignOffStatusId ==
          this.$constants.ASSIGNED_DOCUMENT_STATUS.ASSIGNED
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    additionalSignatures() {
      return this.edited.signatures.createdBy == undefined
        ? []
        : this.edited.signatures.additional;
    },
    signatureCellStyle() {
      return (signatureTypeId) => {
        switch (signatureTypeId) {
          case this.$constants.SIGNATURE_TYPES.CREATED_BY:
            return this.edited.signatures.createdBy != undefined
              ? { 'border-left': '5px #4CAF50 solid' }
              : { 'border-left': '5px red solid' };
          case this.$constants.SIGNATURE_TYPES.SUPERVISOR:
            return this.edited.signatures.supervisor != undefined
              ? { 'border-left': '5px #4CAF50 solid' }
              : { 'padding-left': '21px' };
          case this.$constants.SIGNATURE_TYPES.MANAGER:
            return this.edited.signatures.manager != undefined
              ? { 'border-left': '5px #4CAF50 solid' }
              : { 'padding-left': '21px' };
          case this.$constants.SIGNATURE_TYPES.SAFETY_REP:
            return this.edited.signatures.safetyRep != undefined
              ? { 'border-left': '5px #4CAF50 solid' }
              : { 'padding-left': '21px' };
          default:
            return '';
        }
      };
    },
    title() {
      return this.item.userEnteredLabel
        ? this.item.userEnteredLabel.length > 0
          ? this.item.userEnteredLabel
          : this.item.documentName
        : '';
    },
    previewUrl() {
      return `${
        process.env.VUE_APP_BASE_URL
      }preview-assigned-document?format=json&jwtToken=${
        this.$store.getters.jwtToken
      }&id=${this.item.id ? this.item.id : 0}`;
    },
  },
  data() {
    return {
      item: {},
      edited: { signatures: {} },
      latitude: '',
      longitude: '',
      projects: [],
      selectedSignatureTypeId: null,
      binaries: [],
      contacts: [],
      users: [],
      reloadAddSignature: 0,
      showLoader: false,
      loaderText: '',
      documentExists: false,
      itemLoaded: false,
      isBadRoute: false,
    };
  },
  methods: {
    getItemDetails() {
      const params = {
        loaderText: 'Loading...',
        id: this.$store.getters.selectedAssignedDocument.id,
      };
      const url = 'get-assigned-document-details?format=json';
      return this.$axios.post(url, params).then((response) => {
        this.item = response.data;
        this.edited = JSON.parse(JSON.stringify(this.item));
      });
    },
    getNecessaryData() {
      const params = {
        loaderText: 'Loading...',
        projectId: this.item.projectId,
      };
      const url = 'get-necessary-data-for-assigned-document?format=json';
      return this.$axios.post(url, params).then((response) => {
        this.projects = response.data.projects;
        this.users = response.data.users;
        this.contacts = response.data.contacts;
      });
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    },
    viewOnMap(signature) {
      if (
        signature == undefined ||
        signature.latitude == '' ||
        signature.longitude == ''
      ) {
        return;
      }

      this.latitude = signature.latitude;
      this.longitude = signature.longitude;
      this.$refs.signatureMap.show();
    },
    downloadSignOff() {
      const params = {
        loaderText: 'Loading...',
        id: this.edited.id,
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const url = 'download-document-sign-off-as-pdf?format=json';
      this.$axios
        .post(url, params, options)
        .then((response) => {
          this.$helpers.downloadFile(
            response.data,
            this.item.userEnteredLabel + '.pdf',
          );
        })
        .catch((error) => error);
    },
    addSignature(signatureTypeId) {
      this.selectedSignatureTypeId = signatureTypeId;
      this.$refs.addSignature.show(signatureTypeId);
    },
    onSignatureCreated(payload, signatureTypeId) {
      const signature = {
        contactCompanyName: payload.contactCompanyName,
        signedByName: payload.signedByName,
        createdOnMobileAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        typeId: signatureTypeId,
        guid: payload.guid,
        studentUserId: payload.studentUserId,
        id: payload.guid,
        comment: payload.comment,
        createdOnMobileBy: this.$store.getters.user.id,
        src: payload.dataURL,
        latitude: this.latitude,
        longitude: this.longitude,
        contactFullname: payload.contactFullname,
        contactGuid: payload.contactGuid,
      };

      this.binaries.push(payload);
      switch (signatureTypeId) {
        case this.$constants.SIGNATURE_TYPES.CREATED_BY:
          this.$set(this.edited.signatures, 'createdBy', signature);
          break;
        case this.$constants.SIGNATURE_TYPES.SUPERVISOR:
          this.$set(this.edited.signatures, 'supervisor', signature);
          break;
        case this.$constants.SIGNATURE_TYPES.SAFETY_REP:
          this.$set(this.edited.signatures, 'safetyRep', signature);
          break;
        case this.$constants.SIGNATURE_TYPES.ADDITIONAL:
          this.edited.signatures.additional
            ? this.edited.signatures.additional.push(signature)
            : this.$set(this.edited.signatures, 'additional', [signature]);
          break;
        case this.$constants.SIGNATURE_TYPES.MANAGER:
          this.$set(this.edited.signatures, 'manager', signature);
          break;
        default:
          break;
      }
      this.selectedSignatureTypeId = null;
    },
    signOff() {
      this.updateToDB()
        .then(async () => {
          for (let i = 0; i < this.binaries.length; i++) {
            await this.uploadBinary(this.binaries[i], i);
          }
          this.showLoader = false;
          this.binaries = [];
          return;
        })
        .then(() => {
          this.getItemDetails();
        })
        .catch((error) => {
          this.showLoader = false;
          return error;
        });
    },
    updateToDB() {
      this.loaderText =
        'Submitting document sign off. This may take a few minutes. Please do not close the tab.';
      this.showLoader = true;
      // reduce request size
      const payload = JSON.parse(JSON.stringify(this.edited));
      for (const key in payload.signatures) {
        payload.signatures[key].src = '';
      }
      const params = {
        documentSignOff: payload,
      };
      const url = 'submit-document-sign-off?format=json';
      return this.$axios.post(url, params);
    },
    async uploadBinary(payload, index) {
      this.loaderText = `Uploading file (${index + 1}/${
        this.binaries.length
      })...`;
      const url = 'upload-binary-for-document-sign-off';
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();
      params.append('documentSignOffId', this.edited.id);
      params.append('file', payload.binary);
      params.append('guid', payload.guid);
      params.append('jwtToken', this.$store.getters.jwtToken);

      await this.$axios
        .post(url, params, headers)
        .then(() => true)
        .catch(() => {
          this.showLoader = false;
        });
    },
    downloadDocument() {
      const params = {
        loaderText: 'Loading...',
        id: this.edited.documentId,
        revision: this.edited.revision,
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const url = 'download-private-library-document?format=json';
      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(response.data, this.edited.originalFilename);
      });
    },
    checkDocumentExists() {
      const params = {
        loaderText: 'Loading...',
        id: this.edited.documentId,
        revision: this.edited.revision,
      };
      const url = 'check-private-library-document-exists?format=json';
      this.$axios.post(url, params).then((response) => {
        this.documentExists = response.data;
        this.itemLoaded = true;
      });
    },
    updateProject() {
      const params = {
        loaderText: 'Loading...',
        id: this.item.id,
        projectId: this.edited.projectId,
      };
      const url = 'update-assigned-document?format=json';
      this.$axios.post(url, params).then(() => {
        this.item.projectId = this.edited.projectId;
      });
    },
    unsaveWarning(e) {
      if (JSON.stringify(this.edited) != JSON.stringify(this.item)) {
        e.preventDefault();
        // a random value here is to trigger the browser's native warning
        return (e.returnValue = '_');
      }
    },
  },
  mounted() {
    if (
      this.$route.name == 'AssignedDocumentDetails' &&
      !phantomAccessCheck(this, 'Assigned Document')
    ) {
      return;
    }
    if (!urlOrganizationCheck(this, 'AssignedDocuments')) {
      return;
    } else {
      this.getItemDetails().then(() => {
        this.getNecessaryData().then(() => {
          this.checkDocumentExists();
        });
      });

      this.getLocation();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isBadRoute) {
      if (JSON.stringify(this.edited) != JSON.stringify(this.item)) {
        this.$root.showMessage(
          'Are you sure?',
          "This assignment hasn't been submitted yet.",
          () => next(),
          () => false,
          'Confirm',
          'Cancel',
        );
        return;
      }
    }
    next();
  },
  created() {
    window.addEventListener('beforeunload', this.unsaveWarning);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.unsaveWarning);
  },
};
</script>

<style>
.signed-document-status div {
  border-radius: 5px;
}
</style>
